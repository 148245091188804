<template>
  <div class="execution general-main-height">
    <avue-crud
      ref="crud"
      :page.sync="page"
      :data="tableData"
      :permission="permissionList"
      :table-loading="tableLoading"
      :option="tableOption"
      @on-load="getList"
      @row-update="handleUpdate"
      @row-save="handleSave"
      @search-change="searchChange"
      @size-change="sizeChange"
      @current-change="currentChange"
      @row-del="rowDel"
    >
      <template slot="menu" slot-scope="scope">
        <el-button
          type="text"
          size="small"
          icon="el-icon-menu"
          @click="handleItem(scope.row, scope.index)"
        >
          字典项
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
      :visible.sync="dialogFormVisible"
      title="字典项管理"
      width="90%"
      @close="dictItemVisible"
    >
      <avue-crud
        ref="crudItem"
        v-model="form"
        :page.sync="itemPage"
        :data="tableDictItemData"
        :permission="permissionList"
        :before-open="handleBeforeOpen"
        :option="tableDictItemOption"
        @size-change="itemSizeChange"
        @current-change="itemCurrentChange"
        @row-update="handleItemUpdate"
        @row-save="handleItemSave"
        @row-del="rowItemDel"
      />
    </el-dialog>
  </div>
</template>

<script>
import { tableDictItemOption, tableOption } from "@/const/crud/admin/dict";
export default {
  name: "Dict",
  data() {
    return {
      searchForm: {},
      form: {
        type: undefined,
        dictId: undefined,
      },
      dictType: undefined,
      dictId: undefined,
      dialogFormVisible: false,
      tableData: [],
      tableDictItemData: [],
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
      },
      itemPage: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
      },
      tableLoading: false,
      tableOption: tableOption,
      tableDictItemOption: tableDictItemOption,
    };
  },
  computed: {
    permissionList() {
      return {};
    },
  },
  methods: {
    //======字典表格相关=====
    getList(page, params) {
      this.tableLoading = true;
      this.axios({
        method: "get",
        url: "/v1/web/dict/list",
        params: Object.assign(
          {
            current: page.currentPage,
            size: page.pageSize,
          },
          params,
          this.searchForm
        ),
      }).then((response) => {
        if (response.data) {
          response.data.forEach((i) => {
            i.system = Number(i.system);
          });
        }

        this.tableData = response.data;
        this.page.total = response.data.length;
        this.tableLoading = false;
      });
    },
    rowDel: function (row) {
      const that = this;
      this.$confirm('是否确认删除数据类型为"' + row.type + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(function () {
        that
          .axios({
            method: "delete",
            url: "/v1/web/dict/delete/" + row.id,
            params: { id: row.id },
          })
          .then(() => {
            that.getList(that.page);
            that.$message.success("删除成功");
          })
          .catch(function () {});
      });
    },
    handleUpdate: function (row, index, done) {
      this.axios({
        method: "put",
        url: "/v1/web/dict/update",
        data: row,
      }).then(() => {
        this.$message.success("修改成功");
        this.getList(this.page);
        done();
      });
    },
    handleSave: function (row, done) {
      this.axios({
        method: "post",
        url: "/v1/web/dict/add",
        data: row,
      }).then(() => {
        this.$message.success("添加成功");
        this.getList(this.page);
        done();
      });
    },
    searchChange(form, done) {
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    //======字典项表格相关=====
    dictItemVisible: function () {
      this.dialogFormVisible = false;
      this.itemPage.currentPage = 1;
    },
    handleItem: function (row) {
      this.dictId = row.id;
      this.dictType = row.type;
      this.getDictItemList();
    },
    getDictItemList() {
      this.dialogFormVisible = true;
      this.axios({
        method: "get",
        url: "/v1/web/dictItem/list/" + this.dictId,
      }).then((response) => {
        this.tableDictItemData = response.data;
      });
    },
    handleBeforeOpen(done) {
      this.form.type = this.dictType;
      this.form.dictId = this.dictId;
      done();
    },
    handleItemSave: function (row, done) {
      this.axios({
        method: "post",
        url: "/v1/web/dictItem/add",
        data: row,
      }).then((response) => {
        this.getDictItemList();
        done();
      });
    },
    handleItemUpdate: function (row, index, done) {
      this.axios({
        method: "put",
        url: "/v1/web/dictItem/update/",
        data: row,
      }).then(() => {
        this.$message.success("修改成功");
        this.getDictItemList();
        done();
      });
    },
    itemSizeChange(pageSize) {
      this.itemPage.pageSize = pageSize;
      this.getDictItemList();
    },
    itemCurrentChange(current) {
      this.itemPage.currentPage = current;
      this.getDictItemList();
    },
    rowItemDel: function (row) {
      const that = this;
      this.$confirm('是否确认删除数据为"' + row.label + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(function () {
        that
          .axios({
            method: "delete",
            url: "/v1/web/dictItem/delete/" + row.id,
            params: { id: row.id },
          })
          .then(() => {
            that.getDictItemList();
            that.$message.success("删除成功");
          })
          .catch(function () {
            //
          });
      });
    },
  },
};
</script>
<style scoped>
.general-main-height .avue-crud {
  padding: 10px;
  margin: 10px;
  width: calc(100% - 40px);
  background-color: #fff;
}
</style>
